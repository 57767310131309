import { Icon, OpenExternalIcon } from '@norges-domstoler/dds-components';
import { renderToStaticMarkup } from 'react-dom/server';
import { NavigateFunction } from 'react-router-dom';
import { getRelativeUrl } from '../Components/Shared/Utils';

interface AnchorInfo {
    anchorURL: string;
    anchorEl: HTMLAnchorElement;
}

export const SUPPORTED_FILE_EXTENSIONS = [
    '.txt',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.pdf',
    '.jpg',
    '.jpeg',
    '.jpe',
    '.gif',
    '.bmp',
    '.png',
    '.svg',
    '.mp4',
    '.webm',
    '.webp',
];

interface AdjustLinksArguments {
    className?: string;
    navigate: NavigateFunction;
}

export function adjustLinks({ className, navigate }: AdjustLinksArguments) {
    className = className ?? 'xhtml-container';
    const xhtmlContainerElement = document.getElementsByClassName(className);

    if (xhtmlContainerElement) {
        const anchorList: AnchorInfo[] = [];

        for (const xhtmlSection of xhtmlContainerElement) {
            const links = xhtmlSection.getElementsByTagName('a');

            for (const link of links) {
                handleAnchorTag(link, anchorList, navigate !== undefined);
            }
        }

        reconstructAnchorLinks(anchorList, navigate);
    }
}

function handleAnchorTag(tempATag: HTMLAnchorElement, anchorList: AnchorInfo[], adjustAnchors: boolean) {
    const href = tempATag.href;
    if (href && href.length > 0) {
        const constructedUrl = new URL(href);

        if (isSupportedFile(tempATag)) {
            handleSupportedFile(tempATag);
        } else if (isExternalURL(constructedUrl)) {
            replaceWithExternalLink(tempATag);
        } else if (shouldAdjustAnchor(adjustAnchors, constructedUrl)) {
            handleAnchorLink(tempATag, anchorList, href);
        } else if (isPhoneNumber(href)) {
            formatPhoneNumber(tempATag);
        } else if (isHttpsLink(constructedUrl)) {
            handleAnchorLink(tempATag, anchorList, href);
        }
    }
}

function isExternalURL(constructedUrl: URL): boolean {
    return constructedUrl.origin !== 'null' && constructedUrl.origin !== location.origin;
}

function isHashLink(constructedUrl: URL): boolean {
    return constructedUrl?.hash !== null && constructedUrl?.hash !== '';
}

function isPhoneNumber(href: string): boolean {
    return href.length > 12 && href.substring(0, 4) === 'tel:';
}

function isSupportedFile(tempATag: HTMLAnchorElement): boolean {
    return SUPPORTED_FILE_EXTENSIONS.some((el) => tempATag.href.includes(el));
}

function shouldAdjustAnchor(adjustAnchors: boolean, constructedUrl: URL): boolean {
    return adjustAnchors && isHashLink(constructedUrl);
}

function isHttpsLink(url: URL) {
    return url.protocol === 'https:';
}

function reconstructAnchorLinks(anchorList: AnchorInfo[], navigate: NavigateFunction) {
    anchorList.forEach(({ anchorEl, anchorURL }) => {
        if (isLinkToDifferentSlug(anchorEl.href)) {
            anchorEl.title = anchorEl.text;
            anchorEl.onclick = (e) => {
                e.preventDefault();
                navigate(anchorURL);
            };
        }
    });
}

function isLinkToDifferentSlug(link: string) {
    const linkUrl = new URL(link);
    const currentUrl = new URL(window.location.href);

    const isInternal = linkUrl.hostname === currentUrl.hostname && linkUrl.protocol === currentUrl.protocol;
    const isDifferentPage = linkUrl.pathname !== currentUrl.pathname;
    return isInternal && isDifferentPage;
}

function handleSupportedFile(tempATag: HTMLAnchorElement): void {
    tempATag.classList.add('monsido_download');
    tempATag.onclick = function (e: MouseEvent) {
        e.stopPropagation();
    };
}

function replaceWithExternalLink(tempATag: HTMLAnchorElement): void {
    const newATag = createExternalLink(tempATag);
    tempATag.replaceWith(newATag);
}

function handleAnchorLink(tempATag: HTMLAnchorElement, anchorList: AnchorInfo[], constructedUrl: string): void {
    anchorList.push({
        anchorURL: getRelativeUrl(constructedUrl),
        anchorEl: tempATag,
    });
}

function formatPhoneNumber(tempATag: HTMLAnchorElement): void {
    const number = tempATag.href.substring(4);
    const match = number.match(/\d{2}/g);

    if (match && match.length === 4) {
        const formattedNumber = `${match[0]} ${match[1]} ${match[2]} ${match[3]}`;
        tempATag.innerHTML = formattedNumber;
    }
}

function createExternalLink(oldaTag: HTMLAnchorElement): HTMLAnchorElement {
    const newATag = document.createElement('a');

    newATag.classList.add('external-link');
    newATag.classList.add('focus-internal-link');

    const icon = <Icon iconSize="small" icon={OpenExternalIcon}></Icon>;
    const staticElement = renderToStaticMarkup(icon);

    newATag.innerHTML = oldaTag.text + `${staticElement}`;
    newATag.href = oldaTag.href;

    if (oldaTag.id) {
        newATag.id = oldaTag.id;
    }

    if (oldaTag.rel) {
        newATag.rel = oldaTag.rel;
    }

    if (oldaTag.title) {
        newATag.title = oldaTag.title;
    }

    return newATag;
}

export function hasHtmlStringAnchorTag(hashLink: string, htmlString: string): boolean {
    if (!hashLink) {
        return false;
    }
    try {
        const wrapper = document.createElement('div');
        wrapper.innerHTML = htmlString;
        const elementWithId = wrapper.querySelector(hashLink);
        return !!elementWithId;
    } catch (e) {
        return false;
    }
}
